<template>
  <div>
    <v-card flat tile>
      <v-overlay :value="overlay" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-account</v-icon>
          CONTACTO
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-toolbar flat color="white">
        <v-btn small color="green" class="mx-2" dark
          ><v-icon left>mdi-content-save</v-icon>Guardar</v-btn
        >
        <v-btn small color="blue" class="mx-2" dark @click="dialogNotif = true"
          ><v-icon left>mdi-note</v-icon>Notificar</v-btn
        >
        <!--
      <v-btn small color="info" class="mx-2"
        ><v-icon left>mdi-check</v-icon>Boton 3</v-btn
      >
      <v-btn small color="info" class="mx-2"
        ><v-icon left>mdi-cogs</v-icon>Boton 4</v-btn
      > -->
      </v-toolbar>
      <v-row class="px-4 mt-2 py-0">
        <v-col cols="6">
          <v-text-field
            v-model="datosContacto.NOMBRE"
            label="Nombre"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="datosContacto.CORREO1"
            label="Email"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="px-4 py-0 my-0">
        <v-col cols="3">
          <v-text-field
            v-model="datosContacto.TIPO_ENTE_DESC"
            label="Tipo"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="datosContacto.EMPRESA_NOMBRE"
            label="Empresa"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="datosContacto.OCUPACION"
            label="Cargo"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="px-4 py-0 my-0">
        <v-col cols="6">
          <v-text-field
            v-model="datosContacto.TELEFONO1"
            label="Teléfono"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="datosContacto.CORREO2"
            label="Email 2"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="px-4 py-0 my-0">
        <v-col cols="6">
          <v-text-field
            v-model="datosContacto.TELEFONO2"
            label="Teléfono 2"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="datosContacto.CELULAR1"
            label="Celular principal"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="px-4 py-0 my-0">
        <v-col cols="6">
          <v-text-field
            v-model="datosContacto.CELULAR2"
            label="Celular 2 / whatsap"
          ></v-text-field>
        </v-col>
        <v-col cols="6"> </v-col>
      </v-row>

      <v-row class="px-4 py-0 my-0">
        <v-col cols="6">
          <v-text-field
            v-model="datosContacto.PAIS"
            label="Pais"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="datosContacto.CIUDAD"
            label="Ciudad"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="px-4 py-0 my-0">
        <v-col cols="12">
          <v-text-field
            v-model="datosContacto.DIRECCION"
            label="Dirección"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="px-4 py-0 my-0">
        <v-col cols="12">
          <v-text-field
            v-model="datosContacto.OBSERVACION"
            label="Observaciones"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="dialogNotif" width="500">
      <v-card class="pa-0 ma-0">
        <v-toolbar height="40" dark color="blue" flat>
          <v-toolbar-title>Notificar</v-toolbar-title>
        </v-toolbar>
        <v-row class="pa-0 ma-0">
          <v-cols class="pa-0 mx-4 mt-2">
            <v-select
              label="Tipo de mensaje"
              :items="notifs"
              item-text="nombre"
              item-value="notif_id"
            ></v-select>
          </v-cols>
        </v-row>

        <v-row class="pa-0 ma-0">
          <v-cols class="pa-4">
            <v-btn small dark color="blue" class="mx-2"
              ><v-icon left>mdi-send</v-icon>Enviar</v-btn
            >

            <v-btn small dark color="blue" class="mx-2"
              ><v-icon left>mdi-cancel</v-icon>Cancelar</v-btn
            >
          </v-cols>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";

export default {
  name: "ContactoForm",
  components: {},

  props: {},
  data: () => ({
    options: {},
    overlay: false,
    datosContacto: {},
    tiposNotif: [],
    dialogNotif: false,
  }),
  computed: {
    ...mapState("master", ["loadingBtn"]),
  },
  methods: {
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setTitleToolbar",
      "setLoadingTable",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarDatos(contactoId) {
      this.setLoadingTable(true);
      this.setUrl("api/contacto");
      this.requestApi({
        method: "GET",
        data: {
          contacto_id: contactoId,
        },
      })
        .then((res) => {
          this.datosContacto = res.data.datos;

          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
  },
  mounted() {
    this.cargarDatos(this.$route.params.contactoId);
  },
};
</script>
